import {extraPaymentRepository} from "../repositories/extraPaymentRepository";

class ExtraPaymentService {

    getExtraPaymentNotices = (query) => {
        return extraPaymentRepository.getExtraPaymentNotices(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getExtraPaymentNoticeById = (id) => {
        return extraPaymentRepository.getExtraPaymentNoticeById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addExtraPaymentNotice = (extraPaymentNotice) => {
        return extraPaymentRepository.addExtraPaymentNotice(extraPaymentNotice);
    };

    deleteExtraPaymentNotice = (extraPaymentNotice) => {
        return extraPaymentRepository.deleteExtraPaymentNotice(extraPaymentNotice);
    };


    getExtraPaymentNoticesCount = (query) => {
        return extraPaymentRepository.getExtraPaymentNoticesCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    sendPaymentReminder = (extraPaymentNoticeId) => {
        return extraPaymentRepository.sendExtraPaymentReminder(extraPaymentNoticeId);
    };


    getExtraPaymentById = (id) => {
        return extraPaymentRepository.getExtraPaymentById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    addExtraPayment = (athleteExtraPayment) => {
        return extraPaymentRepository.addExtraPayment(athleteExtraPayment);
    };

    updateExtraPayment = (athleteExtraPayment) => {
        return extraPaymentRepository.updateExtraPayment(athleteExtraPayment);
    };

    deleteExtraPayment = (athleteExtraPayment) => {
        return extraPaymentRepository.deleteExtraPayment(athleteExtraPayment);
    };

    getExtraPayments = (query) => {
        return extraPaymentRepository.getExtraPayments(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getExtraPaymentsCount = (query) => {
        return extraPaymentRepository.getExtraPaymentsCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    downloadAthleteExtraPaymentReceipt = (athleteExtraPaymentId) => {
        return extraPaymentRepository.downloadAthleteExtraPaymentReceipt(athleteExtraPaymentId);
    };

    downloadAllExtraPaymentReceipt = (query) => {
        return extraPaymentRepository.downloadAllExtraPaymentReceipt(query);
    };

    downloadAllExtraPaymentNoticeReceipt = (query) => {
        return extraPaymentRepository.downloadAllExtraPaymentNoticeReceipt(query);
    };
    getExtraPaymentsTotalAmount = (query) => {
        return extraPaymentRepository.getExtraPaymentsTotalAmount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    getExtraPaymentsNoticeTotalAmount = (query) => {
        return extraPaymentRepository.getExtraPaymentsNoticeTotalAmount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    addDonation = (extraPaymentNotice) => {
        return extraPaymentRepository.addDonation(extraPaymentNotice)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    deleteBulkExtraPaymentNotices = (bulkActionRequest) => {
        return extraPaymentRepository.deleteBulkExtraPaymentNotices(bulkActionRequest);
    };
    sendBulkExtraPaymentReminder = (bulkActionRequest) => {
        return extraPaymentRepository.sendBulkExtraPaymentReminder(bulkActionRequest);
    };
}

export default new ExtraPaymentService();

import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";
import ActivityPdfReader from "./ActivityPdfReader";

const StaffActivities = EgretLoadable({
    loader: () => import("./StaffActivities")
});

const settings = {
    activeLayout: "layout1",
    layout1Settings: {
        topbar: {
            show: false
        },
        leftSidebar: {
            show: false,
            mode: "close"
        }
    },
    layout2Settings: {
        mode: "full",
        topbar: {
            show: false
        },
        navbar: { show: false }
    },
    secondarySidebar: { show: false },
    footer: { show: false }
};

const staffActivitiesRoutes = [
    {
        path: "/calendar/extra",
        exact: true,
        component: StaffActivities,
        auth: userUtilsService.getAllClubRoles()
    },
    {
        path: "/calendar/extra/pdf/:id",
        component: ActivityPdfReader,
        auth: userUtilsService.getAllClubRoles(),
        settings
    }
];

export default staffActivitiesRoutes;

import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class coachRepository {

    /**
     * Get coaches
     * @returns {Promise}
     * @param query
     */
    static getCoaches(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/coach/${helperService.serialize(query)}`
        );
    }

    /**
     * Get coaches count
     * @returns {Promise}
     */
    static getCoachesCount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/coach/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Get coach by id
     * @returns {Promise}
     * @param id
     */
    static getCoachById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/coach/${id}`
        );
    }

    /**
     * Get coach by accessUser id
     * @returns {Promise}
     * @param id
     */
    static getCoachByAccessUserId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/coach/access-user/${id}`
        );
    }

    /**
     * Update coach
     * @returns {Promise}
     */
    static updateCoach(coach) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/coach/`, {
            method: 'PUT',
            body: JSON.stringify(coach)
        });
    }

    /**
     * Delete coach
     * @returns {Promise}
     */
    static deleteCoach(coach) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/coach/${coach.id}`, {
            method: 'DELETE'
        });
    }

    static updateMainCoachOption(id, mainCoach) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/coach/main-coach?id=${id}&mainCoach=${mainCoach}`, {
            method: 'POST'
        });
    }

    /**
     * Update coach properties
     * @returns {Promise}
     */
    static updateCoachProperties(coachRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/coach/`, {
            method: 'PATCH',
            body: JSON.stringify(coachRequest)
        });
    }

}

import {paymentRepository} from "../repositories/paymentRepository";

class PaymentService {

    getHigherReceiptDocumentNumber = (clubId) => {
        return paymentRepository.getHigherReceiptDocumentNumber(clubId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    getHigherInvoiceDocumentNumber = (clubId) => {
        return paymentRepository.getHigherInvoiceDocumentNumber(clubId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getPaymentReceiptLanguage = () => {
        return paymentRepository.getPaymentReceiptLanguage()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    generateGamePaymentLink = (athleteId, competitionEventId) => {
        return paymentRepository.generateGamePaymentLink(athleteId, competitionEventId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    generateGamePaymentLinkUpdated = (athleteId, competitionId) => {
        return paymentRepository.generateGamePaymentLinkUpdated(athleteId, competitionId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    generateCampPaymentLink = (athleteId, campEventId) => {
        return paymentRepository.generateCampPaymentLink(athleteId, campEventId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    generateCampPaymentLinkUpdated = (athleteId, campId) => {
        return paymentRepository.generateCampPaymentLinkUpdated(athleteId, campId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    generateGamePaymentLinkByEventExpense = (eventExpenseId) => {
        return paymentRepository.generateGamePaymentLinkByEventExpense(eventExpenseId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    generateCampPaymentLinkByEventExpense = (eventExpenseId) => {
        return paymentRepository.generateCampPaymentLinkByEventExpense(eventExpenseId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    generateSubscriptionPaymentLink = (subscriptionId) => {
        return paymentRepository.generateSubscriptionPaymentLink(subscriptionId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    generateExtraNoticePaymentLink = (extraPaymentNoticeId) => {
        return paymentRepository.generateExtraNoticePaymentLink(extraPaymentNoticeId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    generateClubSubscriptionPaymentLink = (clubId, clubSubscriptionId) => {
        return paymentRepository.generateClubSubscriptionPaymentLink(clubId, clubSubscriptionId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new PaymentService();

import presidentService from "../../shared/services/presidentService";
import i18n from "../../../i18n";
import {EPresidentResponseCodes} from "../../shared/enums/ERespondeCodes";
import coachService from "../../shared/services/coachService";
import {GET_COACH_LIST, GET_COACH_LIST_TIMESTAMP, GET_COACHES_COUNT, GET_COACHES_COUNT_TIMESTAMP} from "./CoachActions";

export const GET_PRESIDENT_LIST = "GET_PRESIDENT_LIST";
export const GET_PRESIDENT_LIST_TIMESTAMP = "GET_PRESIDENT_LIST_TIMESTAMP";
export const GET_PRESIDENT_COUNT = "GET_PRESIDENT_COUNT";
export const GET_PRESIDENT_COUNT_TIMESTAMP = "GET_PRESIDENT_COUNT_TIMESTAMP";

export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const PRESIDENT_LOADING = "PRESIDENT_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

// export function getPresidents(query) {
//     return dispatch => {
//         return presidentService
//             .getPresidents(query)
//             .then(( data ) => {
//                 dispatch({
//                     type: GET_PRESIDENT_LIST,
//                     data: data
//                 });
//                 return Promise.resolve();
//             });
//     }
// }

export function getPresidents(query) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_PRESIDENT_LIST_TIMESTAMP,
            data: currentTime
        });
        return presidentService
            .getPresidents(query)
            .then(( data ) => {
                dispatch({
                    type: GET_PRESIDENT_LIST,
                    data: {
                        list: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function getPresidentsCount (countMap) {
    return dispatch => {
        const currentTime = new Date().getTime();
        dispatch({
            type: GET_PRESIDENT_COUNT_TIMESTAMP,
            data: currentTime
        });
        return presidentService
            .getPresidentsCount(countMap)
            .then((data) => {
                dispatch({
                    type: GET_PRESIDENT_COUNT,
                    data: {
                        count: data,
                        time: currentTime
                    }
                });
                return Promise.resolve();
            });
    }
}

export function updatePresident(president) {
    return dispatch => {
        dispatch({
            type: PRESIDENT_LOADING
        });
        return presidentService
            .updatePresident(president)
            .then(res => {

                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EPresidentResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EPresidentResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function erasePresidentMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}

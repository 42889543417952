export const EInventoryStaffStatus = {
    RECEIVED: 'RECEIVED',
    RETURNED: 'RETURNED'
};


export const EInventoryStaffStatusTranslated = {
    RECEIVED: 'menu.staffInventory.status.received',
    RETURNED: 'menu.staffInventory.status.returned'
};

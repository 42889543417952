import {staffActivityRepository} from "../repositories/staffActivityRepository";
import StaffActivity from "../models/club/StaffActivity";

class StaffActivityService {

    addStaffActivity = (staffActivity) => {
        return staffActivityRepository.addStaffActivity(staffActivity);
    };
    addStaffActivityFile = (file) => {
        return staffActivityRepository.addStaffActivityFile(file);
    };

    deleteStaffActivity = (id) => {
        return staffActivityRepository.deleteStaffActivity(id);
    };

    getStaffActivities = (query) => {
        return staffActivityRepository.getStaffActivities(query)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new StaffActivity(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateStaffActivity = (extraNotification) => {
        return staffActivityRepository.updateStaffActivity(extraNotification);
    };
    deleteStaffActivities = (idList) => {
        return staffActivityRepository.deleteStaffActivities(idList);
    }

}

export default new StaffActivityService();

import {presidentRepository} from "../repositories/presidentRepository";
import {coachRepository} from "../repositories/coachRepository";

class PresidentService {

    getPresidents = (query) => {
        return presidentRepository.getPresidents(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getPresidentsCount = (query) => {
        return presidentRepository.getPresidentsCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getPresidentById = (id) => {
        return presidentRepository.getPresidentById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getPendingPresidentByClubId = (id) => {
        return presidentRepository.getPendingPresidentByClubId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getPresidentByClubId = (id) => {
        return presidentRepository.getPresidentByClubId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getPresidentByAccessUserId = (id) => {
        return presidentRepository.getPresidentByAccessUserId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                //return Promise.reject(err);
                window.location.reload();
            });
    };

    addPresident = (president) => {
        return presidentRepository.addPresident(president);
    };

    updatePresident = (president) => {
        return presidentRepository.updatePresident(president);
    };

    deletePresident = (president) => {
        return presidentRepository.deletePresident(president);
    };

    updatePresidentProperties = (presidentRequest) => {
        return presidentRepository.updatePresidentProperties(presidentRequest).catch(error => {
            window.location.reload();
        });
    };

}

export default new PresidentService();

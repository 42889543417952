import {
  ERASE_MESSAGE, GET_PRESIDENT_LIST,
  PRESIDENT_LOADING, SAVE_ERROR, SAVE_SUCCESS,
  GET_PRESIDENT_LIST_TIMESTAMP,
  GET_PRESIDENT_COUNT, GET_PRESIDENT_COUNT_TIMESTAMP
} from "../actions/PresidentActions";

const initialState = {
  presidentList: [],
  getPresidentsTimestamp: null,
  count: 0,
  getPresidentsCountTimestamp: null,
  loading: false,
  success: null,
  error: null
};

const PresidentReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_PRESIDENT_LIST_TIMESTAMP: {
      return {
        ...state,
        getPresidentsTimestamp: action.data
      };
    }
    case GET_PRESIDENT_LIST: {
      if (action.data.time === state.getPresidentsTimestamp) {
        return {
          ...state,
          presidentList: action.data.list
        };
      } else {
        return state;
      }
    }
    case GET_PRESIDENT_COUNT_TIMESTAMP: {
      return {
        ...state,
        getPresidentsCountTimestamp: action.data
      };
    }
    case GET_PRESIDENT_COUNT: {
      if (action.data.time === state.getPresidentsCountTimestamp) {
        return {
          ...state,
          count: action.data.count
        };
      } else {
        return state;
      }
    }    case PRESIDENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default PresidentReducer;

import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class staffActivityRepository {

    /**
     * Add staff activity file
     * @returns {Promise}
     * @param file
     */
    static addStaffActivityFile (file) {
        const formData = new FormData();
        formData.append("file", file);
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/staffActivity/file`, {
            method: 'POST',
            body: formData
        }, true);
    };
    static addStaffActivity (staffActivity) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/staffActivity`, {
            method: 'POST',
            body: JSON.stringify(staffActivity)
        });
    };


    /**
     * Delete staffActivity
     * @returns {Promise}
     */
    static deleteStaffActivity(id) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/staffActivity?id=` + id, {
            method: 'DELETE'
        });
    }
    /**
     * Delete staffActivities
     * @returns {Promise}
     */
    static deleteStaffActivities(idList) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/staffActivity/data`, {
            method: 'DELETE',
            body: JSON.stringify(idList)
        });
    }

    /**
     * Get staffActivities
     * @returns {Promise}
     * @param query
     */
    static getStaffActivities(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/staffActivity/${helperService.serialize(query)}`
        );
    }

    /**
     * Update extraNotification
     * @returns {Promise}
     */
    static updateStaffActivity(staffActivity) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/staffActivity`, {
            method: 'PATCH',
            body: JSON.stringify(staffActivity)
        });
    }

}

import React, {Component} from "react";
import {Dialog, Grid, Icon, IconButton, Input, MenuItem, Select, TextField, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {PaperComponent} from "../../shared/components/PaperComponent";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import cloneDeep from 'lodash/cloneDeep';
import {EVisibleForUsers, EVisibleForUsersTranslated} from "./../../shared/enums/EVisibleForUsers";
import teamService from "../../shared/services/teamService";
import {ETeamStatus} from "../../shared/enums/ETeamStatus";

const styles = theme => ({
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

class UpdateSportDialog extends Component {
    state = {
        sportIds: [],
        visibleForUsers : this.props.document.visibleForUsers,
        teamIds: [],
        selectedTeams : this.props.allTeams
    };

    componentDidMount() {
        const t = this.props.t;
        this.setState({
            ...this.state,
            sportIds: cloneDeep(this.props.document.visibleForSportIds),
            visibleForUsers : this.props.document.visibleForUsers,
            teamIds : cloneDeep(this.props.document.visibleForTeamIds)
        });

    }

    checkSportTeams = (sportId) => {
        const allTeams = this.props.allTeams;
        const newTeams = cloneDeep(this.state.selectedTeams);
        allTeams.forEach(team => {
            if(team.sportId === sportId)
                newTeams.push(team);
        });
        return newTeams.sort((a,b) => a.name.localeCompare(b.name));
    }

    uncheckSportTeams = (sportId) => {
        const selectedTeams = cloneDeep(this.state.selectedTeams);
        const newTeams = [];
        selectedTeams.forEach(team => {
            if(team.sportId !== sportId)
                newTeams.push(team);
        });
        return newTeams.sort((a,b) => a.name.localeCompare(b.name));
    }

    updateSport = (id) => {
        let updatedSportIds = cloneDeep(this.state.sportIds);
        const selectedTeams = this.state.selectedTeams;
        let newSelectedTeams = [];
        const newTeamIds = [];

        if (!updatedSportIds.includes(id)) {
            updatedSportIds.push(id);
            newSelectedTeams = this.checkSportTeams(id);
        } else {
            updatedSportIds.splice(updatedSportIds.indexOf(id), 1);
            newSelectedTeams = this.uncheckSportTeams(id);
        }
        newSelectedTeams.forEach(team => {
            newTeamIds.push(team.id != null ? team.id : team.teamId);
        })


        this.setState({
            ...this.state,
            sportIds: updatedSportIds,
            teamIds: newTeamIds,
            selectedTeams: newSelectedTeams
        })
    };
    updateTeam = (id) => {
        let updatedTeamIds = cloneDeep(this.state.teamIds);
        if (!updatedTeamIds.includes(id)) {
            updatedTeamIds.push(id);
        } else {
            updatedTeamIds.splice(updatedTeamIds.indexOf(id), 1);
        }
        this.setState({
            ...this.state,
            teamIds: updatedTeamIds
        })
    };

    handleSelectChange = (event) => {
        event.persist();
        this.setState({
            ...this.state,
            visibleForUsers: event.target.value
        })
    };

    getTitle = (document) => {
        let text = document ? document.fileName ? document.fileName + "." + document.extension : document.comment : "";
        if (text)
            return this.getTrimText(text)
        else return "";
        // return document ? document.fileName ? document.fileName + "." + document.extension : document.comment : "";
    }
    getTrimText = (text) => {
        let div = document.createElement("div");
        div.innerHTML = text;
        text = div.textContent || div.innerText || "";

        const splitText = text.split(' ');
        const messageToDisplay = splitText.slice(0, 4).join(' ');
        if (splitText.slice(0, 4).join(' ') !== splitText.slice(0, 5).join(' ')) {
            return messageToDisplay + ' ...';
        } else {
            return messageToDisplay;
        }
    }

    render() {

        const {t, open, handleClose, classes, allSports, document, i18n, handleSave, goBack, messageSaveButton} = this.props;
        const {selectedTeams} = this.state;


        return (
            <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md" PaperComponent={PaperComponent}>
                <div className="flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary fixed-content"
                     style={{cursor: 'move'}} id="draggable-dialog-title">
                    <h4 className="m-0 text-white">{t('office.doc.labels.visibilityCriteria', {documentName: this.getTitle(document)})}</h4>
                    <div className="flex flex-space-between flex-middle">
                        <IconButton onClick={handleClose}>
                            <Icon className="text-white">clear</Icon>
                        </IconButton>
                    </div>
                </div>
                <DialogContent className="p-0">
                    <div className="p-16">
                        <ValidatorForm ref="form" onSubmit={this.handleFormSubmit} onError={this.handleError}>
                            <div style={{"display": "flex"}}>
                                <div style={{"display": "grid", justifyContent: "flex-start", alignItems: "flex-start", height: "100%"}}>
                                    <h5  className="m-0 text-black">{t('office.doc.visibleForSports')} </h5>
                                    {allSports.map((sport, index) => (
                                        <MenuItem key={index} value={sport.id}
                                                  onClick={() => {this.updateSport(sport.id)}}
                                                  disabled={this.props.document.notificationSent}>
                                            <Checkbox
                                                checked={this.state.sportIds.indexOf(sport.id) > -1}/>
                                            <ListItemText primary={sport.name[i18n.language]}/>
                                        </MenuItem>
                                    ))}
                                </div>
                                {selectedTeams && ( <div style={{"display": "grid", marginLeft: "50px",  justifyContent: "flex-start", alignItems: "flex-start", height: "100%"}}>
                                    <h5  className="m-0 text-black">{t('office.doc.visibleForTeams')} </h5>
                                    {selectedTeams.map((team, index) => (
                                        <MenuItem key={index} value={team.id != null ? team.id : team.teamId}
                                                  onClick={() => this.updateTeam(team.id != null ? team.id : team.teamId)}
                                                  disabled={this.props.document.notificationSent}>
                                            <Checkbox
                                                checked={this.state.teamIds.indexOf(team.id != null ? team.id : team.teamId) > -1}/>
                                            <ListItemText primary={team.name}/>
                                        </MenuItem>
                                    ))}
                                </div> )}
                                <div style={{"display": "grid", marginLeft: "50px", height: "160px"}}>
                                    <h5  className="m-0 text-black">{t('office.doc.labels.visibleForUsers')} </h5>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label={t('office.doc.labels.userTypes')}
                                        select
                                        name="visibleForUsers"
                                        value={this.state.visibleForUsers ? this.state.visibleForUsers : EVisibleForUsers.STAFF}
                                        onChange={this.handleSelectChange}
                                        id={"visibleForUsers"}
                                        fullWidth
                                        validators={[
                                            "required"
                                        ]}
                                        errorMessages={[t('validation.error.required')]}
                                        disabled={this.props.document.notificationSent}
                                    >
                                        {Object.entries(EVisibleForUsers).map(([key, value], index) => (
                                            <MenuItem value={value} key={index}>
                                                {t(EVisibleForUsersTranslated[key])}
                                            </MenuItem>
                                        ))}
                                    </TextValidator>
                                </div>
                            </div>
                            {this.state.sportIds && this.state.sportIds.length>0 && (
                            <div className={classes.wrapper}
                                 style={{"display": "flex", "justifyContent": "space-evenly", "paddingTop": "50px"}}>
                                {this.props.allTeams != null && <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { goBack(document)}}
                                >
                                    <span className="capitalize">{t('office.notification.backButton')}</span>
                                </Button>}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={this.props.document.notificationSent}
                                    onClick={() => {
                                        document.visibleForSportIds = this.state.sportIds ? this.state.sportIds : [];
                                        document.visibleForUsers = this.state.visibleForUsers;
                                        document.visibleForTeamIds = this.state.teamIds ? this.state.teamIds : []
                                        handleSave(document);
                                    }}
                                >
                                    {this.props.allTeams != null ? messageSaveButton ? <span className="capitalize">{messageSaveButton}</span> : <span className="capitalize">{t('office.notification.generateNotification')}</span> : <span className="capitalize">{t('form.actions.save')}</span>}
                                </Button>
                            </div>
                            )}
                        </ValidatorForm>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    team: state.team
});

export default withStyles(styles, {withTheme: true})(
    connect(
        mapStateToProps,
        {}
    )(withTranslation()(UpdateSportDialog))
);

import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class presidentRepository {

    /**
     * Get presidents
     * @returns {Promise}
     * @param query
     */
    static getPresidents(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/president/${helperService.serialize(query)}`
        );
    }

    /**
     * Get presidents count
     * @returns {Promise}
     */
    static getPresidentsCount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/president/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Get president by id
     * @returns {Promise}
     * @param id
     */
    static getPresidentById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/president/${id}`
        );
    }

    /**
     * Get pending president by club id
     * @returns {Promise}
     * @param id
     */
    static getPendingPresidentByClubId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/president/pending/${id}`
        );
    }

    /**
     * Get president by club id
     * @returns {Promise}
     * @param id
     */
    static getPresidentByClubId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/president/club/${id}`
        );
    }

    /**
     * Get president by accessUser id
     * @returns {Promise}
     * @param id
     */
    static getPresidentByAccessUserId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/president/access-user/${id}`
        );
    }

    /**
     * Add new president
     * @returns {Promise}
     */
    static addPresident(president) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/president/`, {
            method: 'POST',
            body: JSON.stringify(president)
        });
    }

    /**
     * Update president
     * @returns {Promise}
     */
    static updatePresident(president) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/president/`, {
            method: 'PUT',
            body: JSON.stringify(president)
        });
    }

    /**
     * Delete president
     * @returns {Promise}
     */
    static deletePresident(president) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/president/`, {
            method: 'DELETE',
            body: JSON.stringify(president)
        });
    }

    /**
     * Update president properties
     * @returns {Promise}
     */
    static updatePresidentProperties(presidentRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/president/`, {
            method: 'PATCH',
            body: JSON.stringify(presidentRequest)
        });
    }

}

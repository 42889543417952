import i18n from "../../../i18n";
import {EStaffActivityResponseCodes} from "../../shared/enums/ERespondeCodes";
import staffActivityService from "../../shared/services/staffActivityService";
export const GET_STAFF_ACTIVITY_LIST = "GET_STAFF_ACTIVITY_LIST";
export const ERASE_MESSAGE = "ERASE_MESSAGE";
export const SAVE_ERROR = "SAVE_ERROR";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const DELETE_ERROR = "DELETE_ERROR";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const STAFF_ACTIVITY_LOADING = "STAFF_ACTIVITY_LOADING";

export function getStaffActivities(query) {
    return dispatch => {
        return staffActivityService
            .getStaffActivities(query)
            .then(( data ) => {
                dispatch({
                    type: GET_STAFF_ACTIVITY_LIST,
                    data: data
                });
                return Promise.resolve();
            });
    }
}


export function addStaffActivity(staffActivity) {
    return dispatch => {
        dispatch({
            type: STAFF_ACTIVITY_LOADING
        });

        return staffActivityService
            .addStaffActivity(staffActivity)
            .then((res) => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EStaffActivityResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EStaffActivityResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}


export function updateStaffActivity(staffActivity) {
    return dispatch => {
        dispatch({
            type: STAFF_ACTIVITY_LOADING
        });
        return staffActivityService
            .updateStaffActivity(staffActivity)
            .then(res => {
                dispatch({
                    type: SAVE_SUCCESS,
                    data: i18n.t(EStaffActivityResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: SAVE_ERROR,
                    data: i18n.t(EStaffActivityResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}
export function deleteStaffActivity(staffActivityId) {
    return dispatch => {
        dispatch({
            type: STAFF_ACTIVITY_LOADING
        });
        return staffActivityService
            .deleteStaffActivity(staffActivityId)
            .then(res => {
                dispatch({
                    type: DELETE_SUCCESS,
                    data: i18n.t(EStaffActivityResponseCodes[res.message])
                });
                return Promise.resolve();
            })
            .catch(error => {
                dispatch({
                    type: DELETE_ERROR,
                    data: i18n.t(EStaffActivityResponseCodes[error.message])
                });
                return Promise.reject();
            });
    };
}

export function initializeList() {
    return dispatch => {
        dispatch({
            type: GET_STAFF_ACTIVITY_LIST,
            data: []
        });
    };
}

export function eraseStaffActivityMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}

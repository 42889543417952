import TrainingPlan from "./TrainingPlan";

class StaffActivity extends TrainingPlan {
    constructor(data) {
        super(data);
        this.visibleForUsers = data.visibleForUsers;
        this.visibleForTeamIds = data.visibleForTeamIds;
        this.comment = data.comment;
    }
}

export default StaffActivity;
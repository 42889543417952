import React, {Component, Fragment} from "react";
import {
    Card,
    Checkbox, CircularProgress,
    Grid,
    Icon,
    IconButton,
    Tooltip,
    withStyles
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import i18n from "../../../i18n";
import './../../../styles/views/_list.scss';
import {ConfirmationDialog} from "../../../egret";
import {withTranslation} from "react-i18next";
import {classList} from "../../../utils";
import {EAthleteStatus, EAthleteStatusTranslated} from "../enums/EAthleteStatus";
import {EUserStatus, EUserStatusTranslated} from "../enums/EUserStatus";
import {helperService} from "../services/helperService";
import {EGameEquipmentStatus, EGameEquipmentStatusTranslated} from "../enums/EGameEquipmentStatus";
import Chip from "@material-ui/core/Chip";
import {withRouter} from "react-router";
import {EVisaStatus, EVisaStatusTranslated} from "../enums/EVisaStatus";
import {ERoleTranslated} from "../enums/ERole";
import {EHealthConditionStatus, EHealthConditionStatusTranslated} from "../enums/EHealthConditionStatus";
import {
    EDisciplinarySituationStatus,
    EDisciplinarySituationStatusTranslated
} from "../enums/EDisciplinarySituationStatus";
import CustomizedSnackbar from "./CustomizedSnackbar";
import {ECardSituationStatus, ECardSituationStatusTranslated} from "../enums/ECardSituationStatus";
import {EInventoryStaffStatus, EInventoryStaffStatusTranslated} from "../enums/EInventoryStaffStatus";

const styles = () => ({
    detailsButton: {
        backgroundColor: "rgba(0,0,0,0.8)",
        borderColor: "white",
        marginTop: "12%",
        color: "white",
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.5)"
        }
    },
    disabledButton: {
        color: "lightgray !important"
    },
    column: {
        position: "relative",
        display: "inline-block",
        verticalAlign: "middle",
        padding: "0 6px",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        height: '60px'
    }
});

class FragmentList extends Component {

    state = {
        selectedItems: [],
        shouldOpenDeleteConfirmationDialog: false,
        error: null
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.selectedItems) {
            this.setState({
                ...this.state,
                selectedItems: nextProps.selectedItems
            });
        }
    }

    getItemValue = (item, field) => {
        let value = '';
        if (!field.includes('.')) {
            value = item[field] !== undefined ? item[field] : '';
        } else {
            const fields = field.split('.');
            value = item[fields[0]][fields[1]] !== undefined ? item[fields[0]][fields[1]] : '';
        }

        if (typeof value === "boolean") {
            return value ? (
                <small className="border-radius-4 bg-primary text-white px-8 py-2">
                    {i18n.t('isMainCoach.true')}
                </small>
            ) : (
                <small className="border-radius-4 bg-light-gray px-8 py-2">
                    {i18n.t('isMainCoach.false')}
                </small>
            );
        } else if (field === 'status') {
            return (
                <small
                    className={classList({
                        "border-radius-4  text-white px-8 py-2": true,
                        "bg-primary": !value || value === '',
                        "bg-green": value === EAthleteStatus.CONFIRMED,
                        "bg-error": value === EAthleteStatus.DENIED,
                    })}
                >
                    {!value || value === '' === '' ? i18n.t(EAthleteStatusTranslated[EAthleteStatus.PENDING]) : i18n.t(EAthleteStatusTranslated[value])}
                </small>
            )
        } else if (field === 'staffInventoryStatus') {
            return (
                <small
                    className={classList({
                        "border-radius-4  text-white px-8 py-2": true,
                        "bg-green": value === EInventoryStaffStatus.RECEIVED,
                        "bg-error": value === EInventoryStaffStatus.RETURNED,
                    })}
                >
                    {i18n.t(EInventoryStaffStatusTranslated[value])}
                </small>
            )
        } else if (field === 'accessUser.status') {
            return (
                <small
                    className={classList({
                        "border-radius-4  text-white px-8 py-2": true,
                        "bg-green": value === EUserStatus.ACTIVE,
                        "bg-secondary": value === EUserStatus.PAUSED,
                        "bg-light-dark": value === EUserStatus.CANCELED,
                        "bg-error": value === EUserStatus.PENDING
                    })}
                >
                    {!value || value === '' === '' ? i18n.t(EUserStatusTranslated[EUserStatus.PENDING]) : i18n.t(EUserStatusTranslated[value])}
                </small>
            )
        } else if (this.props.showMoreDetails === true && (field === 'firstName' || field === 'lastName' || field === 'accessUser.email')) {
            return (
                <a className="text-primary"
                   style={{cursor: 'pointer'}}
                   onClick={() => this.props.handleViewClick(item.id)}
                   title={value}
                >
                    {' '}{value}
                </a>
            )
        } else if (field === 'grade' && value === 'CONFIDENTIAL') {
            return (
                <small
                    className={classList({
                        "border-radius-4  text-white px-8 py-2 bg-primary": true
                    })}
                >
                    {value}
                </small>
            )
        } else if (field === 'date' || value instanceof Date) {
            return value ? helperService.dateUniversalFormat(value) : '';
        } else if (field === 'equipmentStatus') {
            return (
                <small
                    className={classList({
                        "border-radius-4  text-white px-8 py-2": true,
                        "bg-green": value === EGameEquipmentStatus.COMPLETE,
                        "bg-secondary": value === EGameEquipmentStatus.INCOMPLETE,
                        "bg-error": value === EGameEquipmentStatus.NONEXISTENT,
                        "bg-primary": value === EGameEquipmentStatus.RETURNED
                    })}
                >
                    {i18n.t(EGameEquipmentStatusTranslated[value])}
                </small>
            )
        } else if (field === 'team') {
            return (
                <Chip
                    key={value.code}
                    label={value.code}
                    className="classes.chip text-white"
                    style={{"backgroundColor": value.color, 'width': '100%', 'maxWidth': '90%'}}
                    onClick={() => {
                        this.props.history.push(`/team/${value.id}`)
                    }}
                />
            )
        } else if (field === 'medicalVisaStatus' || field === 'annualFederationVisaStatus') {
            return (
                <small
                    className={classList({
                        "border-radius-4  text-white px-8 py-2": true,
                        "bg-green": value === EVisaStatus.VALID,
                        "bg-secondary": value === EVisaStatus.ABOUT_TO_EXPIRE,
                        "bg-error": value === EVisaStatus.EXPIRED,
                    })}
                >
                    {i18n.t(EVisaStatusTranslated[value])}
                </small>
            )
        } else if (this.props.showMoreDetails === true && field.includes('userFullName')) {
            const userId = field.includes('createdBy') ? item.createdBy.userId : item.lastModifiedBy.userId;
            return (
                <a className="text-primary"
                   style={{cursor: 'pointer'}}
                   onClick={() => this.props.handleViewClick(userId)}
                >
                    {' '}{value}
                </a>
            );
        } else if (field === 'accessUser.role.name') {
            const roleName = value.name;
            return (
                <span title={this.props.t(ERoleTranslated[roleName])}>
                    {this.props.t(ERoleTranslated[roleName])}
                </span>
            );
        } else if (field === 'lastModifiedByDate' || value instanceof Date) {
            return value ? helperService.dateUniversalFormat(value) : '';
        } else if (field === 'lastModified' || value instanceof Date) {
            return value ? helperService.dateUniversalFormat(value) : '';
        }else if (field === 'value') {
            return Number(value).toFixed(2);
        } else if (field === 'healthCondStatus') {
            return (
                <small
                    className={classList({
                        "border-radius-4  text-white px-8 py-2": true,
                        "bg-green": value === EHealthConditionStatus.FIT,
                        "bg-error": value === EHealthConditionStatus.INJURED
                    })}>
                    {i18n.t(EHealthConditionStatusTranslated[value])}
                </small>
            )
        } else if (field === 'disciplinaryStatus') {
            return (
                <small
                    className={classList({
                        "border-radius-4  text-white px-8 py-2": true,
                        "bg-green": value === EDisciplinarySituationStatus.VALID,
                        "bg-error": value === EDisciplinarySituationStatus.SUSPENDED
                    })}>
                    {i18n.t(EDisciplinarySituationStatusTranslated[value])}
                </small>
            )
        } else if (field === 'cardStatus') {
            return (
                <small
                    className={classList({
                        "border-radius-4 px-8 py-2": true,
                        "bg-error": value === ECardSituationStatus.RED_CARD,
                        "bg-yellow": value === ECardSituationStatus.YELLOW_CARD,
                        "bg-blue": value === ECardSituationStatus.BLUE_CARD
                    })}>
                    {i18n.t(ECardSituationStatusTranslated[value])}
                </small>
            )
        } else if (field === 'cost') {
            return (
                <span>
                    {value} {item['currency']}
                </span>
            );
        }
        else if (field === 'evolutionPercentage') {
            if (value === 0) {
                return (<div style={{textAlign: "center"}}><span>{"-"}</span></div>);
            }
            else {
                let order = item.orderResult
                if (order === false) {
                    if (value > 0) {
                        return (<div style={{justifyContent: "center", display: "flex"}}>
                            <span style={{color: "red", fontWeight: "bold"}}>{value + "%"}</span>
                            <Icon style={{color: "red", backgroud: 'transparent', paddingLeft: "5px"}}>arrow_downward</Icon></div>);
                    }
                    else  if (value < 0) {
                        return ( <div style={{justifyContent: "center",display: "flex"}}>
                            <span style={{color: "green", fontWeight: "bold"}}>{Math.abs(value) + "%"}</span>
                                <Icon style={{color: "green", backgroud: 'transparent', paddingLeft: "5px"}}>arrow_upward</Icon>
                        </div>);
                    }
                }
                else if (order === true) {
                    if (value > 0) {
                        return (<div style={{justifyContent: "center",display: "flex"}}>
                            <span style={{color: "green", fontWeight: "bold"}}>{value + "%"}</span>
                            <Icon style={{color: "green", backgroud: 'transparent', paddingLeft: "5px"}}>arrow_upward</Icon>
                        </div>);
                    }
                    else  if (value < 0) {
                        return (
                            <div style={{justifyContent: "center",display: "flex"}}>
                                <span style={{color: "red", fontWeight: "bold"}}>{Math.abs(value) + "%"}</span>
                                <Icon style={{color: "red", backgroud: 'transparent', paddingLeft: "5px"}}>arrow_downward</Icon></div>);
                    }
                }
            }
        }
        else if (field === 'referencePercentage') {
            if (value === "") {
                let missingRef = item.missingRefValue
                if (missingRef === true)  return ( <Tooltip title= {i18n.t('menu.test.result.details.viewTest')}>
                    <Button onClick = {() => { this.props.handleViewClick()}}>
                        <span style={{color: "red", fontWeight: "bold"}}>{i18n.t('menu.test.result.details.noRefValue')}</span>
                    </Button>
                </Tooltip>);
                return ( <Tooltip title= {i18n.t('menu.test.result.details.viewTest')}>
                    <Button onClick = {() => { this.props.handleViewClick()}}>
                        <span style={{color: "red", fontWeight: "bold"}}>{i18n.t('menu.test.result.details.changeRefValue')}</span>
                    </Button>
                </Tooltip>);
            }
            else if (value === 0) {
                return (<div style={{textAlign: "center"}}><span>{"-"}</span></div>);
            }
            else {
                let order = item.orderResult
                if (order === false) {
                    if (value > 0) {
                        return (<div style={{justifyContent: "center",display: "flex"}}>
                            <span style={{color: "red", fontWeight: "bold"}}>{value + "%"}</span>
                            <Icon style={{color: "red", backgroud: 'transparent', paddingLeft: "5px"}}>arrow_downward</Icon></div>);
                    }
                    else  if (value < 0) {
                        return ( <div style={{justifyContent: "center",display: "flex"}}>
                            <span style={{color: "green", fontWeight: "bold"}}>{Math.abs(value) + "%"}</span>
                        <Icon style={{color: "green", backgroud: 'transparent', paddingLeft: "5px"}}>arrow_upward</Icon></div>);
                    }
                }
                else if (order === true) {
                    if (value > 0) {
                        return (<div style={{justifyContent: "center",display: "flex"}}>
                            <span style={{color: "green", fontWeight: "bold"}}>{value + "%"}</span>
                            <Icon style={{color: "green", backgroud: 'transparent', paddingLeft: "5px"}}>arrow_upward</Icon>
                        </div>);
                    }
                    else  if (value < 0) {
                        return (
                            <div style={{justifyContent: "center",display: "flex"}}>
                            <span style={{color: "red", fontWeight: "bold"}}>{Math.abs(value) + "%"}</span>
                            <Icon style={{color: "red", backgroud: 'transparent', paddingLeft: "5px"}}>arrow_downward</Icon></div>);
                    }
                }
            }
        }
        return <span title={value}>{value}</span>;
    };

    onSelect = (item) => {
        if (item && item.teamsCount && item.teamsCount < 2) {
            this.setState({
                ...this.state,
                error: i18n.t('team.onlyOne')
            });
            return;
        }
        if (this.props.onSelect !== undefined) {
            this.props.onSelect(item);
        } else {
            let items = this.state.selectedItems;
            if (!items.includes(item.id)) {
                items.push(item.id);
            } else {
                items = items.filter(selectedItemId => item.id !== selectedItemId);
            }
            this.setState({
                ...this.state,
                selectedItems: items
            });
        }
    };

    handleDeleteConfirmationDialog = (value) => {
        this.setState({
            shouldOpenDeleteConfirmationDialog: value
        });
    };

    handleConfirmationRemoveResponse = () => {
        this.props.handleConfirmationRemoveResponse(this.state.selectedItems);
        this.setState({
            shouldOpenDeleteConfirmationDialog: false,
            selectedItems: []
        });
    };

    isSecondActionDisabled = (item) => {
        return this.props.isSecondActionDisabled ? this.props.isSecondActionDisabled(item) : false;
    };

    getColumnsWidth = (columns) => {
        const columnsWidth = [];
        columns.forEach(column => {
            if (columns.includes('form.email') && columns.includes('form.phone')) {
                if (column.includes('email')) {
                    columnsWidth.push(`calc(${(100 / columns.length)}% + 125px`);
                } else if (column.includes('phone')) {
                    columnsWidth.push(`calc(${(100 / columns.length)}% + 25px`);
                } else {
                    columnsWidth.push(`calc(${(100 / columns.length)}% - ${150 / (columns.length - 2)}px`);
                }
            }
            else if (columns.includes('form.referenceTests') && columns.includes('Modifica')) {
                if (column.includes('referenceTests')) {
                    columnsWidth.push(`calc(${(100 / columns.length)}% + 120px`);
                }
                else if (column.includes('Modifica')) {
                    columnsWidth.push(`calc(${(100 / columns.length)}% - 68px`);
                }
                else {
                    columnsWidth.push(`calc(${(100 / columns.length)}% - 16px`);
                }
            }
            else if (columns.includes('form.referenceTests') && !columns.includes('form.Modifica')) {
                if (column.includes('referenceTests')) {
                    columnsWidth.push(`calc(${(100 / columns.length)}% + 120px`);
                }
                else {
                    columnsWidth.push(`calc(${(100 / columns.length)}% - 30px`);
                }
            }
            else if (columns.includes('table.head.inventoryStaff.name')) {
                if (column.includes('table.head.inventoryStaff.name')) {
                    columnsWidth.push(`calc(${(100 / columns.length)}% + 90px`);
                }  else if (column.includes('table.head.inventoryStaff.quantity')) {
                    columnsWidth.push(`calc(${(100 / columns.length)}% - 30px`);
                } else if (column.includes('table.head.inventoryStaff.value')) {
                    columnsWidth.push(`calc(${(100 / columns.length)}% - 30px`);
                } else if (column.includes('table.head.inventoryStaff.currency')) {
                    columnsWidth.push(`calc(${(100 / columns.length)}% - 30px`);
                } else {
                    columnsWidth.push(`${(100 / columns.length)}%`);
                }
            }
            else {
                columnsWidth.push(`${(100 / columns.length)}%`);
            }
        });
        return columnsWidth;
    };

    eraseError() {
        this.setState({
            error: null
        });
    };
    renderListView = (list, columns, fields) => {
        const {showCheckbox, showActions, classes, t} = this.props;
        const {selectedItems} = this.state;
        if (this.props.showActionColumn !== false) {
            columns = columns.concat([this.props.actionColumnName ? this.props.actionColumnName : t('table.head.general.action')]);
        }
        if(this.props.showActionColumn !== false && this.props.deleteColumnName){
            columns = columns.concat([this.props.deleteColumnName ? this.props.deleteColumnName : t('table.head.general.action')]);
        }
        /*if (showCheckbox) {
            columns = (this.props.checkboxColumnName ? [this.props.checkboxColumnName] : ['']).concat(columns);
        }*/
        const columnsWidth = this.getColumnsWidth(columns);
        return (
            <div>
                {showActions && this.renderActions()}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div>
                        <div className="pb-2 px-4" style={{display: 'flex', justifyContent: 'center'}}>
                            {showCheckbox && (
                                <div style={{width: '50px'}}/>
                            )}
                            <div style={{width: showCheckbox ? 'calc(100% - 50px)' : '100%'}}>
                                {columns.map((column, index) => (
                                    (column.includes('referenceTests') ?  <div className={classes.column} style={{width: columnsWidth[index], textAlign: "center"}} key={index}>  <span className="font-weight-500 text-muted ml-4">{t(column)}</span> </div>
                                           : column.includes('evolutionTests') ? <div className={classes.column} style={{width: columnsWidth[index], textAlign: "center"}} key={index}> <span className="font-weight-500 text-muted ml-4">{t(column)}</span> </div> : <div className={classes.column} style={{width: columnsWidth[index]}} key={index}> <span className="font-weight-500 text-muted ml-4">{t(column)}</span> </div>
                                    )
                                ))}
                            </div>
                        </div>
                        {list.map((item, index) => (
                            <Fragment key={item.id}>
                                <Card className={`py-8 px-4 ${classes.root}`}
                                      style={index % 2 ? {background: "#fdffe0", flex: 1} : {
                                          background: "#fafafa",
                                          flex: 1
                                      }}>
                                    {showCheckbox === true && (
                                        <div style={{width: '50px'}}>
                                            <Checkbox
                                                disabled={this.props.withoutConfirmation ? false : (this.props.isCheckboxDisabled && this.props.isCheckboxDisabled(item)) || item.status !== EAthleteStatus.CONFIRMED}
                                                checked={selectedItems.includes(item.id)}
                                                onChange={() => this.onSelect(item)}
                                            />
                                        </div>
                                    )}
                                    <div style={{width: showCheckbox ? 'calc(100% - 50px)' : '100%'}}>
                                        {fields.map((field, index) => (
                                            <div className={classes.column} style={{width: columnsWidth[index]}}
                                                 key={index}>
                                                <span className="overflowHidden font-weight-500 ml-4">
                                                    {this.getItemValue(item, field)}
                                                </span>
                                            </div>))
                                        }
                                        {this.props.showActionColumn !== false && (
                                            <span style={{width: columnsWidth[index]}}>
                                                {this.props.secondAction &&
                                                    (this.props.isSecondActionDisplayed ? this.props.isSecondActionDisplayed(item) : true) && (
                                                        <Tooltip
                                                            title={this.isSecondActionDisabled(item) ? '' : (this.props.tooltipSecondAction || '')}>
                                                        <span>
                                                            <IconButton
                                                                onClick={() => this.props.handleSecondActionClick(item.id)}
                                                                disabled={this.isSecondActionDisabled(item)}
                                                            >
                                                                <Icon
                                                                    style={this.isSecondActionDisabled(item) ? {} : this.props.secondActionStyle}>{this.props.secondAction}</Icon>
                                                            </IconButton>
                                                        </span>
                                                        </Tooltip>
                                                    )}
                                            </span>
                                        )}
                                        {this.props.showActionColumn !== false && (
                                            <span style={{width: columnsWidth[index], marginLeft: '75px'}}>
                                                {this.props.thirdAction &&
                                                    (
                                                        <Tooltip
                                                            title={(this.props.tooltipThirdAction || '')}>
                                                        <span>
                                                            <IconButton
                                                                onClick={() => this.props.handleThirdActionClick(item.id)}
                                                                disabled={false}
                                                            >
                                                                <Icon
                                                                    style={this.props.thirdActionStyle}>{this.props.thirdAction}</Icon>
                                                            </IconButton>
                                                        </span>
                                                        </Tooltip>
                                                    )}
                                            </span>
                                        )}
                                    </div>
                                </Card>
                                <div className="py-8"/>
                            </Fragment>
                        ))}
                    </div>
                    <CustomizedSnackbar message={this.state.error} severity="error" onClose={() => this.eraseError()}/>
                </Grid>
            </div>
        );
    };

    renderGridView = (list, topFields, bottomFields) => {
        const {classes, showCheckbox, showActions} = this.props;
        const {selectedItems} = this.state;
        return (
            <div className="list">
                <div className="grid-view">
                    {showActions && this.renderActions()}
                    <Grid container>
                        {list.map((item) => (
                            <Grid item lg={12} md={12} sm={12} xs={12} key={item.id} className="m-8"
                                  style={{height: "200px"}}>
                                <Card className="grid__card flex-column h-100" elevation={6}
                                      style={{backgroundColor: '#f2f2f2'}}>
                                    <div className="grid__card-top text-center" style={{height: "170px"}}>
                                        <h4 style={{marginTop: "11.5%"}}>
                                            {topFields.map((field, index) => (
                                                <div key={index}>{this.getItemValue(item, field)}</div>
                                            ))}
                                        </h4>
                                        {this.props.showMoreDetails !== false && (
                                            <div className="grid__card-overlay flex-column">
                                                <div className="flex flex-middle flex-center">
                                                    <Button
                                                        variant="outlined"
                                                        className={classes.detailsButton}
                                                        onClick={() => this.props.handleViewClick(item.id)}
                                                    >
                                                        {i18n.t('actions.viewDetails')}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="grid__card-bottom text-center py-8">
                                        {bottomFields.map((field, index) => (
                                            <div key={index}>
                                                {showCheckbox === true && (index === bottomFields.length - 1) && (
                                                    <Checkbox
                                                        disabled={this.props.isCheckboxDisabled ? this.props.isCheckboxDisabled(item) : false}
                                                        checked={selectedItems.includes(item.id)}
                                                        onChange={() => this.onSelect(item)}
                                                    />
                                                )}
                                                <span key={index}
                                                      className="m-0">{this.getItemValue(item, field)}</span>
                                                {this.props.secondAction && (index === bottomFields.length - 2) &&
                                                    (this.props.isSecondActionDisplayed ? this.props.isSecondActionDisplayed(item) : true) && (
                                                        <Tooltip title={this.props.tooltipSecondAction || ''}>
                                                        <span>
                                                            <IconButton
                                                                onClick={() => this.props.handleSecondActionClick(item.id)}
                                                                disabled={this.isSecondActionDisabled(item)}
                                                            >
                                                                <Icon
                                                                    style={this.isSecondActionDisabled(item) ? {} : this.props.secondActionStyle}>{this.props.secondAction}</Icon>
                                                            </IconButton>
                                                        </span>
                                                        </Tooltip>
                                                    )
                                                }
                                                {this.props.thirdAction && (index === bottomFields.length - 1) &&
                                                    (
                                                        <Tooltip title={this.props.tooltipThirdAction || ''}>
                                                        <span>
                                                            <IconButton
                                                                onClick={() => this.props.handleThirdActionClick(item.id)}
                                                                disabled={false}
                                                            >
                                                                <Icon
                                                                    style={this.props.thirdActionStyle}>{this.props.thirdAction}</Icon>
                                                            </IconButton>
                                                        </span>
                                                        </Tooltip>
                                                    )
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            </Grid>
                        ))}
                        <CustomizedSnackbar message={this.state.error} severity="error"
                                            onClose={() => this.eraseError()}/>
                    </Grid>
                </div>
            </div>
        );
    };

    renderActions() {
        let {addButtonLabel, removeConformationText, removeButtonLabel, isRemoveActive} = this.props;
        return (
            <div  className="ml-8">
                <span className="font-weight-500 text-muted mr-8"
                >{i18n.t("team.actions")}</span>
                <IconButton
                    color="primary"
                    title={addButtonLabel || i18n.t('actions.add')}
                    onClick={this.props.openAddDialog}>
                    <Icon>add</Icon>
                </IconButton>
                {isRemoveActive && <IconButton
                    disabled={this.state.selectedItems.length === 0}
                    style={{color: "red", pointerEvents: 'auto'}}
                    title={removeButtonLabel || i18n.t('actions.remove')}
                    onClick={() => this.handleDeleteConfirmationDialog(true)}>
                    <Icon>remove</Icon>
                     </IconButton>}

                {this.state.shouldOpenDeleteConfirmationDialog && (
                    <ConfirmationDialog
                        open={this.state.shouldOpenDeleteConfirmationDialog}
                        onConfirmDialogClose={() => this.handleDeleteConfirmationDialog(false)}
                        onYesClick={this.handleConfirmationRemoveResponse}
                        text={removeConformationText}
                    />
                )}
            </div>
        );
    }

    render() {
        const {list, columns, topFields, bottomFields, emptyListMessage, showActions} = this.props;
        const fields = topFields.concat(bottomFields);
        if (list === null || list === undefined || list.length === 0) {
            return (
                <div>
                    {showActions && this.renderActions()}
                    <span className="font-weight-500 text-muted ml-8">{emptyListMessage}</span>
                </div>
            );
        } else if (window.innerWidth > 600) {
            return this.renderListView(list, columns, fields);
        } else {
            return this.renderGridView(list, topFields, bottomFields)
        }
    }
}

export default withStyles(styles, {withTheme: true})(
    withRouter(
        (withTranslation()(FragmentList))
    )
);

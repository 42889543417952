import {
    STAFF_ACTIVITY_LOADING,
    ERASE_MESSAGE, GET_STAFF_ACTIVITY_LIST, SAVE_ERROR, SAVE_SUCCESS, DELETE_SUCCESS, DELETE_ERROR
} from "../actions/StaffActivityActions";

const initialState = {
    success: null,
    error: null,
    staffActivitiesList: [],
};

const StaffActivityReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_STAFF_ACTIVITY_LIST: {
            return {
                ...state,
                staffActivitiesList: action.data
            };
        }
        case STAFF_ACTIVITY_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case SAVE_SUCCESS: {
            return {
                ...state,
                success: action.data,
                loading: false,
                error: null
            };
        }
        case SAVE_ERROR: {
            return {
                ...state,
                success: null,
                loading: false,
                error: action.data
            };
        }
        case DELETE_SUCCESS: {
            return {
                ...state,
                success: action.data,
                loading: false,
                error: null
            };
        }
        case DELETE_ERROR: {
            return {
                ...state,
                success: null,
                loading: false,
                error: action.data
            };
        }
        case ERASE_MESSAGE: {
            return {
                ...state,
                error: null,
                success: null
            };
        }
        default: {
            return state;
        }
    }
};

export default StaffActivityReducer;
